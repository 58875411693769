:root {
  --base-shadow: 0 1px 10px rgba(0, 0, 0, 0.2);
  --hover-shadow: 3px 7px 15px rgba(0, 0, 0, 0.1),
    -1px 3px 25px rgba(0, 0, 0, 0.1);
  --cyan900: #0a2129;
  --cyan800: #154251;
  --cyan700: #1f637a;
  --cyan600: #2984a3;
  --cyan500: #34a5cb;
  --cyan400: #5cb7d6;
  --cyan300: #85c9e0;
  --cyan200: #aedbea;
  --cyan100: #d6edf5;
  --cyan50: #ebf6fa;
  --green900: #18260d;
  --green800: #304c1a;
  --green700: #487227;
  --green600: #609834;
  --green500: #79bf42;
  --green400: #93cb67;
  --green300: #aed88d;
  --green200: #c9e5b3;
  --green100: #e4f2d9;
  --green50: #f1f9ec;
  --yellow900: #302303;
  --yellow800: #5f4707;
  --yellow700: #8f6a0a;
  --yellow600: #bf8e0d;
  --yellow500: #efb212;
  --yellow400: #f2c140;
  --yellow300: #f5d070;
  --yellow200: #f8e0a0;
  --yellow100: #fcefcf;
  --yellow50: #fdf7e7;
  --red900: #260d11;
  --red800: #4d1922;
  --red700: #732634;
  --red600: #9a3245;
  --red500: #c03f56;
  --red400: #cd6578;
  --red300: #d98c9a;
  --red200: #e6b2bb;
  --red100: #f2d9dd;
  --red50: #f9ecee;
  --coolGray900: #17181C;
  --coolGray800: #2D2F39;
  --coolGray700: #444755;
  --coolGray600: #5A5E72;
  --coolGray500: #71768E;
  --coolGray400: #8D91A5;
  --coolGray300: #AAADBB;
  --coolGray200: #C6C8D2;
  --coolGray100: #E3E4E8;
  --coolGray50: #F1F1F4;
}
* {
  transition: opacity 0.2s ease-out, background-color 0.2s ease-out,
    transform 0.2s, box-shadow 0.2s;
}

html {
  margin: 0;
  font-family: 'Fira Code', Courier, monospace;
  padding: 0;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  font: 20px/1.45 'Fira Code', Courier, monospace;
  box-sizing: border-box;
  overflow-y: scroll;
  font-size: 10px;
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: inherit;
  font-weight: normal;
  word-wrap: break-word;
  font-kerning: normal;
  -moz-font-feature-settings: 'kern', 'liga', 'clig', 'calt';
  -ms-font-feature-settings: 'kern', 'liga', 'clig', 'calt';
  -webkit-font-feature-settings: 'kern', 'liga', 'clig', 'calt';
  font-feature-settings: 'kern', 'liga', 'clig', 'calt';
  color: var(--coolGray800);
  --text-color: var(--coolGray800);
  --menu-icon: var(--title-color);
  --bg: var(--coolGray50);
  background-color: var(--bg);
  margin: 0;
  padding: 0;
  min-height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  --focus-shadow: 0px 1px 15px rgba(255, 100, 100, 0.4);
  --bright-focus-shadow: 0px 1px 15px rgba(255, 255, 20, 0.7);
  --dark-focus-shadow: 0px 1px 15px rgba(255, 100, 100, 0.7);
  --gradient-green-end: var(--green500);
  --gradient-cyan-end: var(--cyan500);
  --wrapper-bg: var(--cyan800);
  --wrapper-border: var(--cyan900);
  --grid-bg: #fff;
  --grid-border: var(--coolGray100);
  --empty-note: var(--cyan100);
  --empty-note-current: var(--cyan200);
  --active-note: var(--cyan500);
  --active-note-current: var(--cyan600);
  --play-btn: var(--cyan500);
  --trash-btn: var(--red300);
  --title-color: #fff;
  --input-bg: var(--cyan700);
  --input-ui: var(--cyan600);
  --input-active: var(--cyan500);
  --input-icon: var(--cyan400);
  --input-label: var(--cyan200);
  --ui-danger: var(--red800);
  --input-accent: var(--yellow500);
  --input-value: #fff;
  background: linear-gradient(
    106.79deg,
    var(--gradient-cyan-end) 6.17%,
    var(--gradient-green-end) 94.78%
  );
  --modal-bg: rgba(10, 33, 41, 0.8);
  --modal-dialogue: var(--coolGray200);
  --modal-input-bg: #fff;
  --modal-text: var(--coolGray700);
  --modal-border: var(--coolGray300);
  --track-list-bg: #fff;
  --colormode-sun: var(--cyan500);
  --colormode-moon: var(--cyan300);
  --button-bg-colormode: var(--cyan100);
  --button-bg-colormode-border: var(--cyan200);
  --button-bg-colormode-hover: var(--cyan50);
  --about: rgba(255,255,255,.4);
  --github: var(--green600);
}

body.dark {
  --focus-shadow: 0px 1px 15px rgba(255, 255, 100, 0.4);
  --bg: var(--coolGray900);
  --wrapper-bg: var(--cyan900);
  --wrapper-border: var(--cyan800);
  --grid-bg: var(--coolGray900);
  --grid-border: var(--coolGray700);
  --empty-note: var(--cyan800);
  --empty-note-current: var(--cyan700);
  --active-note: var(--cyan600);
  --active-note-current: var(--cyan500);
  background: var(--bg);
  --modal-bg: rgba(10, 33, 41, 0.9);
  --modal-dialogue: var(--coolGray900);
  --modal-input-bg: var(--coolGray800);
  --modal-text: var(--coolGray300);
  --modal-border: var(--coolGray700);
  --track-list-bg: var(--coolGray800);
  color: var(--coolGray200);
  --title-color: #fff;
  --text-color: var(--coolGray200);
  --menu-bg: var(--cyan800);
  --colormode-sun: var(--yellow300);
  --colormode-moon: var(--yellow500);
  --button-bg-colormode: var(--cyan700);
  --button-bg-colormode-border: var(--cyan600);
  --button-bg-colormode-hover: var(--cyan600);
  --about: var(--coolGray600);
  --github: var(--coolGray900);
}
*,
* > * {
  box-sizing: border-box;
}
h1 {
  font-size: 20px;
  display: inline-block;
  margin: 0 0.75em 0;
  color: var(--title-color);
}
h3 {
  margin: 0 0 0.75em 0;
  color: var(--text-color);
}
